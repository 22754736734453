function OptanonWrapper() {
	var cookieBannerContainer = document.getElementById('onetrust-consent-sdk');
	document.body.prepend(cookieBannerContainer);

	// Move Manage button into button group
	var bannerActionsContainer = document.querySelector('.banner-actions-container');
	var bannerBtnHandlerManage = document.getElementById('onetrust-pc-btn-handler');
	// if (bannerActionsContainer && bannerBtnHandlerManage) {
	// 	bannerActionsContainer.append(bannerBtnHandlerManage);
	// }

	// Wrap the Accept button and append the Reject button
	var preferenceBtnHandlerAccept = document.getElementById('accept-recommended-btn-handler');
	var preferenceBtnHandlerReject = document.querySelector('.ot-pc-refuse-all-handler');
	var preferenceBtnVirtualWrapper = createElementWithClass('div', 'virtual-ot-btn-wrapper');
	wrapElement(preferenceBtnHandlerAccept, preferenceBtnVirtualWrapper);
	if (preferenceBtnHandlerReject) {
		preferenceBtnVirtualWrapper.append(preferenceBtnHandlerReject);
	}

	if (!getCookie('OptanonAlertBoxClosed')) {
		// Expand accordion
		var accordionButtons = document.querySelectorAll('.ot-accordion-layout button');
		for (var i = 0; i < accordionButtons.length; i++) {
			accordionButtons[i].setAttribute('aria-expanded', 'true');
		}
	}

	// Handle inital load
	// var cookieBannerOverlay = document.getElementsByClassName('onetrust-pc-dark-filter');
	// var overlayControl = document.querySelector(".overlay-control");
	// if (!overlayControl) {
	// 	if (cookieBannerOverlay[0] && window.getComputedStyle(cookieBannerOverlay[0]).display === 'block')  {
	// 		document.body.classList.add('ot-overflow-hidden');
	// 	} else {
	// 		document.body.classList.remove('ot-overflow-hidden');
	// 	}
	// }


	// // Handle close/submit
	// document.querySelectorAll('#onetrust-accept-btn-handler,.onetrust-close-btn-handler,#accept-recommended-btn-handler,.save-preference-btn-handler, #onetrust-reject-all-handler, .ot-pc-refuse-all-handler').forEach(function(item) {
	// 	item.addEventListener('click', function() {
	// 		document.body.classList.remove('ot-overflow-hidden');
	// 	});
	// });


	function getCookie(name) {
		var value = '; ' + document.cookie;
		var parts = value.split('; ' + name + '=');
		if (parts.length == 2) {
			return parts
				.pop()
				.split(';')
				.shift();
		}
	}

	if (getCookie("OTVariant")) {
		var cookieVariantValue = getCookie("OTVariant");
		dataLayer.push({
			"testId3": cookieVariantValue
		})
	}

	function createElementWithClass(el, className) {
		element = document.createElement('div');
		element.className = className;
		return element;
	}

	function wrapElement(el, wrapper) {
		el.parentNode.insertBefore(wrapper, el);
		wrapper.appendChild(el);
	}


	const gpcEnabled = navigator.globalPrivacyControl;
	if (gpcEnabled) {
        var cookieBanner = document.getElementById("onetrust-consent-sdk");
        cookieBanner.classList.toggle("gpc-detected");
	}

	var gpcOnController = document.querySelector(".gpc-on"),
		gpcOKButton = document.getElementById("cookieGPCControlButton"),
		buttonContainer = document.querySelector(".banner-actions-container")

	if (gpcOnController) {
		buttonContainer.appendChild(gpcOKButton);

		if (gpcEnabled) {
			gpcOKButton.addEventListener('click', function() {
				OneTrust.Close();
				document.body.classList.remove('ot-overflow-hidden');
			});
		}
		else {
			gpcOKButton.addEventListener('click', function() {
				OneTrust.AllowAll();
				document.body.classList.remove('ot-overflow-hidden');
			});
		}
	}
}
